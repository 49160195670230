import { Link } from 'react-router-dom'
import {connect} from 'react-redux'

import moment from 'moment'
import { useState, useEffect } from 'react'
//constants
import { REQUEST_STATUS, OPTION_STATUS } from "../../../constant/request.status"
import { REASONS_DECLINE } from "../../../constant/dictionary"

//components
import ActionButton from '../../assets/elements/button/action-button'
import CancelButton from '../../assets/elements/button/secondary-action-button'
import Loading from '../../assets/controls/loading'


import CustomPopup from '../../assets/elements/custom-popup'
import CustomRadio from '../../assets/elements/input/custom-radio'
import CustomDate from '../../assets/elements/input/custom-date'
import CustomTextarea from '../../assets/elements/input/custom-textarea'
import RequestPopupData from './request-popup-data'

//images
import ViewIcon from '../../../images/admin-icons/eye-icon.svg'
import {ReactComponent as TrashIcon} from '../../../images/admin-icons/trash-icon.svg'

//utils
import RequestHelper from '../../../utils/helper';
import { postReserveOption, postDeclineOption, putCancelBooking, postRequestExtension,postRequestTermination, putCancelReservation,putCancelPendingExtension,putAcceptPendingExtension } from "../../../utils/my-requests"
//styles
import '../styles/request-properties-card-options.css'

const RequestPropertiesCard = ({ loading, option_id, request_id, request_status, property_id, property_name, property_address, unit_size, distance, parking, property_grade, final_rate, floor_plan, dates_available, checkInDate, length_of_stay, floor_level, maid_service, telephone, ntv_requirement, option_status, currency_code,hiddeActions,bookedFilter,checkOutDate,extensions,completedExtensions,terminations }) => {
    const [visibleDeclinedPopup, setVisibleDeclinedPopup] = useState(false)
    const [visiblePendingExtensionPopup, setVisiblePendingExtensionPopup] = useState(false)
    const [acceptPendingExtensionPopup, setAcceptPendingExtensionPopup] = useState(false)
    const [visibleCancelReservationPopup, setVisibleCancelReservationPopup] = useState(false)
    const [visibleCancelBookingPopup, setVisibleCancelBookingPopup] = useState(false)
    const [visibleRequestExtension, setVisibleRequestExtension] = useState(false)
    const [visibleRequestTermination, setVisibleRequestTermination] = useState(false)

    const [requestExtensionInfo, setRequestExtensionInfo] = useState(null)
    const [declinedReason, setDeclinedReason] = useState(null)
    const [commentDeclinedReason, setCommentDeclinedReason] = useState("")
    const [startDateRequestExtension,setStartDateRequestExtension] = useState(null)
    const [endDateRequestExtension, setEndDateRequestExtension] = useState(null)
    const [commentRequestExtensionReason, setCommentRequestExtensionReason] = useState("")
    const [terminationDate, setTerminationDate] = useState(null)
    const [terminationComment, setTerminationComment] = useState("")
    const [localCompletedExtensions, setLocalCompletedExtensions] = useState([])
    const [localTerminations, setLocalTerminations] = useState([])

    const Missing = () => {
        return (<span className='missing-text'>Missing</span>)
    }

    const reserveOption = async() => {
        await postReserveOption(option_id, request_id);
        setCurrentStatus({...currentStatus, requestStatus: REQUEST_STATUS.ReservationCreated, optionStatus: OPTION_STATUS.PendingManager });
    }
    const cancelRequestTermination = _ => {
        setTerminationDate(null)
        setVisibleRequestTermination(false)
        setTerminationComment("")
    }
    const declineOption = async e => {
        e.preventDefault()

            await postDeclineOption(request_id,option_id, declinedReason?.id, commentDeclinedReason);
            setCurrentStatus({...currentStatus, requestStatus: REQUEST_STATUS.Quoted, optionStatus: OPTION_STATUS.DeclinedByRecipient });
            setVisibleDeclinedPopup(false)
    }
    const cancelDeclineOption = _ => {
        setVisibleDeclinedPopup(false)
        setDeclinedReason(null)
        setCommentDeclinedReason("")
    }
    const cancelReservation = async e => {
        e.preventDefault()
        await putCancelReservation(request_id, option_id);
        setVisibleCancelReservationPopup(false)
        setCurrentStatus({ ...currentStatus,requestStatus: REQUEST_STATUS.Quoted, optionStatus: OPTION_STATUS.Quoted })
    }

    const cancelBooking = async () => {
        await putCancelBooking(request_id, option_id);
        setCurrentStatus({...currentStatus, requestStatus: REQUEST_STATUS.PendingManager, optionStatus: OPTION_STATUS.Booked })
    }


    const [currentStatus, setCurrentStatus] = useState({});
    const requestExtension = async e =>{
        e.preventDefault()
        let response = await postRequestExtension(request_id,option_id,moment.utc(startDateRequestExtension).utcOffset(0).set({ hour:0, minute:0, second:0, millisecond:0}).format('YYYY-MM-DD HH:mm:ss'),moment.utc(endDateRequestExtension).utcOffset(0).set({ hour:0, minute:0, second:0, millisecond:0}).format('YYYY-MM-DD HH:mm:ss'),commentRequestExtensionReason)
        if(response)
        {
            let data= {
                extensionId: response,
                startDate: moment(new Date(startDateRequestExtension).toISOString()).format(RequestHelper.getLocalDateFormat()),
                endDate: moment(new Date(endDateRequestExtension).toISOString()).format(RequestHelper.getLocalDateFormat()),
                comment: commentRequestExtensionReason
            }
            setRequestExtensionInfo(data)
        }
        setVisibleRequestExtension(false)
        setEndDateRequestExtension(null)
        setCommentRequestExtensionReason("")
    }
    const requestTermination = async e => {
        e.preventDefault()
        let response = await postRequestTermination(request_id,option_id,moment.utc(terminationDate).utcOffset(0).set({ hour:0, minute:0, second:0, millisecond:0}).format('YYYY-MM-DD HH:mm:ss'),terminationComment)
        
        if(response)
            {
                let data= {
                    extensionId: response,
                    terminationDate: moment(new Date(terminationDate).toISOString()).format(RequestHelper.getLocalDateFormat()),
                    residentComment: terminationComment,
                    termination: true
                }
                setRequestExtensionInfo(data)
            }
        cancelRequestTermination()
    }
    const cancelRequestExtension =  _ => {
        setVisibleRequestExtension(false)
        setEndDateRequestExtension(null)
        setCommentRequestExtensionReason("")
    }
    const cancelPendingExtension = async(e,id) => {
        try {
            e.preventDefault()
            await putCancelPendingExtension(id)
            setVisiblePendingExtensionPopup(false)
            setRequestExtensionInfo(null)
            let [startMonth,startDay,startYear] = requestExtensionInfo.startDate.split("/")
            let [endMonth,endDay,endYear] = requestExtensionInfo.endDate.split("/")

            setLocalCompletedExtensions([...localCompletedExtensions,{
                startDate: `${startDay}/${startMonth}/${startYear}`,
                endDate: `${endDay}/${endMonth}/${endYear}`
            }])
        } catch (error) {
        }
        
    }
    const acceptPendingExtension = async(e,extensionId) => {
        try {
            e.preventDefault()
            await putAcceptPendingExtension(extensionId,request_id)
            setRequestExtensionInfo({...requestExtensionInfo,statusId: 4})
            setAcceptPendingExtensionPopup(false)
        } catch (error) {
        }
        
    }
    useEffect(() => {
        setCurrentStatus({...currentStatus, requestStatus: request_status, optionStatus: option_status });
    }, [request_id,request_status])
    
    const OptionActionBtn = ({currentStatus, requestExtensionInfo}) => {

        if ((currentStatus.optionStatus === OPTION_STATUS.Quoted && currentStatus.requestStatus === REQUEST_STATUS.Quoted) || (currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && (currentStatus.optionStatus === OPTION_STATUS.New || currentStatus.optionStatus === OPTION_STATUS.Quoted))) {
            return (<>
                <div className='request-properties-card-options__buttons__content__new'>
                    <div className='request-properties-card-options__buttons__content__new_content-wrapper'>
                        <div className='d-flex'>
                            <ActionButton action={reserveOption} disabled={currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated || loading}>Reserve</ActionButton>
                            <Loading isVisble={loading} size={30}/>
                        </div>
                        {currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && 
                            <div className='request-properties-card-options__buttons__content__new__blocked-message'>
                                <span>*</span>You should cancel current reservation, if you want to proceed with this option
                            </div>
                        }
                    </div>
                    
                </div>
                <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                    <ActionButton action={_=>setVisibleDeclinedPopup(true)} disabled={loading}>Decline</ActionButton>
                </div>

            </>)
        } else if (currentStatus.optionStatus === OPTION_STATUS.DeclinedByRecipient || (REQUEST_STATUS.ReservationCreated === currentStatus.requestStatus && currentStatus.optionStatus !== OPTION_STATUS.PendingManager)) {
            return (<></>)
        } else if (currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && currentStatus.optionStatus === OPTION_STATUS.PendingManager) {
            return (<>
                <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                    <ActionButton action={_ => setVisibleCancelReservationPopup(true)}>Cancel Reservation</ActionButton>
                </div>
            </>)
        } else if ((currentStatus.requestStatus === REQUEST_STATUS.Booked || currentStatus.requestStatus === REQUEST_STATUS.PendingExtension || currentStatus.requestStatus === REQUEST_STATUS.ExtensionRequested) && currentStatus.optionStatus === OPTION_STATUS.Booked) {
            if (moment(checkInDate).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isAfter(moment())) {
                return (<>
                    <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                        <ActionButton action={_ => setVisibleCancelBookingPopup(true)} disabled={request_status === REQUEST_STATUS.PendingExtension}>Cancel Booking</ActionButton>
                    </div>
                </>)
            } else {
                if(!requestExtensionInfo)
                    return (<>
                        <div className='request-properties-card-options__buttons__content__new'>
                            <ActionButton action={_=>setVisibleRequestExtension(process.env.REACT_APP_TESTING_COMPONENT === 'testing' ? true : false)}>Request Extension</ActionButton>
                            <ActionButton action={_ => setVisibleRequestTermination(process.env.REACT_APP_TESTING_COMPONENT === 'testing' ? true : false)}>Request Termination</ActionButton>
                        </div>
                    </>)
                else
                   return(<>
                    <div className='request-properties-card-options__message__pending-extension'>
                        {
                            (requestExtensionInfo.statusId === 6 ||  requestExtensionInfo.statusId === 4) && !requestExtensionInfo.termination ?
                            <>
                                <div className='request-properties-card-options__message__pending-extension__pending-confirmation__title'>
                                    {requestExtensionInfo.statusId === 4 ? 'Pending to finalize extension:' : 'Extension approved by manager:'}
                                </div>
                                <div className='request-properties-card-options__message__pending-extension__pending-confirmation__content'>
                                    <div><span>Dates: </span>{requestExtensionInfo.startDate} - {requestExtensionInfo.endDate}</div>
                                    <div><span>Ntv Requirement (Days): {requestExtensionInfo.ntvRequirement}</span></div>
                                    <div><span>Extension Rate: </span>{requestExtensionInfo.finalRate} {currency_code}</div>
                                    {requestExtensionInfo.statusId === 6 && <div className='request-properties-card-options__message__pending-extension__pending-confirmation__buttons'>
                                        <div>
                                        <ActionButton action={()=>setAcceptPendingExtensionPopup(true)}>Accept</ActionButton>
                                        </div>
                                        
                                        <div className='request-properties-card-options__buttons__content__new btn-declined'>
                                            <ActionButton>Decline</ActionButton>
                                        </div>
                                    </div>}
                                </div>
                            </>
                            :
                            <>
                                <div className='request-properties-card-options__message__pending-extension__title'>{requestExtensionInfo.termination ? 'Pending Termination:' : 'Pending Extension:'}</div>
                                <div className='request-properties-card-options__message__pending-extension__dates'>{requestExtensionInfo.termination ? requestExtensionInfo.terminationDate : `${requestExtensionInfo.startDate} - ${requestExtensionInfo.endDate}`}</div>
                                <div className='request-properties-card-options__message__pending-extension__comment'>{requestExtensionInfo.termination ? requestExtensionInfo.residentComment : requestExtensionInfo.comment}</div>
                                <div className='request-properties-card-options__message__pending-extension__cancel' onClick={_=>{!requestExtensionInfo.termination && setVisiblePendingExtensionPopup(true)}}><TrashIcon/><span>Cancel</span></div>
                            </>
                        }
                        
                    </div>
                   </>)
            }
        } 
    }

    const getStatusLabel = (statusId) => {
        switch (statusId) {
            case OPTION_STATUS.Quoted:
                return "new";
            case OPTION_STATUS.PendingSupplier:
            case OPTION_STATUS.PendingManager:
                return "pending manager";
            case OPTION_STATUS.Booked:
                return "booked";
            case OPTION_STATUS.DeclinedByRecipient:
                return "declined";
            default:
                return "error";
        }
    }
    useEffect(() => {
      if(extensions?.length > 0 )
        {
            let data = null
            extensions.forEach(extension => {
                if(extension.statusId !== 5)
                {
                    data= {
                        ...extension,
                        startDate: moment(extension.startDate).format(RequestHelper.getLocalDateFormat()),
                        endDate: moment(extension.endDate).format(RequestHelper.getLocalDateFormat()),
                    }
                }
            })
            setRequestExtensionInfo(data)
        }
      if(terminations?.length > 0)
      {
        let data = null
            terminations.forEach(termination => {
                if(termination.statusId !== 5)
                {
                    data= {
                        ...termination,
                        terminationDate: moment(termination.terminationDate).format(RequestHelper.getLocalDateFormat()),
                        termination: true
                    }
                }
            })
            setRequestExtensionInfo(data)
      }
    }, [extensions,terminations])

    useEffect(() => {
      setLocalCompletedExtensions(completedExtensions)
      if(completedExtensions?.filter(item => item.interruptionReasonId === 5)?.length > 0 )
      {
        const latestDate = completedExtensions?.filter(item => item.interruptionReasonId === 5)?.reduce((latest,item) =>{
            const currentEndDate = new Date(item.originEndDate)
            const latestEndDate = new Date(latest.originEndDate)
            return currentEndDate > latestEndDate ? item : latest
        })
        setStartDateRequestExtension(latestDate.originEndDate)
      }
      else{
        setStartDateRequestExtension(checkOutDate)
      }
    }, [completedExtensions])
    
    useEffect(() => {
      setLocalTerminations(terminations)
    }, [terminations])
    

  return (
    <div className='request-properties-card-options' style={bookedFilter ? currentStatus.optionStatus === 7 ? {} : {display:'none'} : {}}>
          <div className='request-properties-card-options__img'><img src={`${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/files/main-option-image/${property_id}`} alt="OptionImg" /></div>
        <div className='request-properties-card-options__information'>
            <div className='request-properties-card-options__information__content'>
                <div className='request-properties-card-options__information__left'>
                    <div className='request-properties-card-options__information__field request-properties-card-options__information__field__option-id'>
                        {/*<div className='request-properties-card-options__information__field__name'>Option Id:</div>*/}
                        {/*<div className='request-properties-card-options__information__field__value'>{option_id}</div>*/}
                          <div className='request-properties-card-options__field-status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
                    </div>
                    <div className='request-properties-card-options__information__field request-properties-card-options__information__field__property-name'>
                        <div className='request-properties-card-options__information__field__name'>Property #:</div>
                        <Link className='request-properties-card-options__information__field__value request-properties-card-options__information__field__value--link' to={`/property/dashboard/${property_id}`} target='_blank'>{property_id}</Link>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Property Address:</div>
                        <div className='request-properties-card-options__information__field__value'>{property_address}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                          <div className='request-properties-card-options__information__field__name'>Unit Size:</div>
                          <div className='request-properties-card-options__information__field__value'>{unit_size ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Distance:</div>
                        <div className='request-properties-card-options__information__field__value'>{distance} miles</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Parking:</div>
                        <div className='request-properties-card-options__information__field__value'>{parking}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Property Grade:</div>
                          <div className='request-properties-card-options__information__field__value'>{property_grade ?? <Missing />}</div>
                    </div>
                </div>
                <div className='request-properties-card-options__information__right'>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Final Rate:</div>
                        <div className='request-properties-card-options__information__field__value'>
                            <span>{`${final_rate} ${currency_code}`}</span> 
                            <div className='request-properties-card-options__information__field__value__tax'> 
                                <span className='request-properties-card-options__information__field__value__tax-icon'>i</span>
                                <span>Plus Tax</span>
                            </div>
                        </div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Floor Plan:</div>
                        <div className='request-properties-card-options__information__field__value'>{floor_plan}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Dates Available:</div>
                        <div className='request-properties-card-options__information__field__value'>{dates_available}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Length Of Stay:</div>
                        <div className='request-properties-card-options__information__field__value'>{length_of_stay}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Floor Level:</div>
                          <div className='request-properties-card-options__information__field__value'>{floor_level ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Maid Service:</div>
                        <div className='request-properties-card-options__information__field__value'>{maid_service}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Telephone:</div>
                          <div className='request-properties-card-options__information__field__value'>{telephone ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Ntv Requirement (Days):</div>
                        <div className='request-properties-card-options__information__field__value'>{ntv_requirement}</div>
                    </div>
                </div>
                <div className='request-properties-card-options__information__extension-list'>
                        {localCompletedExtensions?.length > 0 && 
                        <>
                            <div className='request-properties-card-options__information__extension-list__separator'></div>
                            <div className='request-properties-card-options__information__extension-list__content'>
                                
                                <div><span>Extensions:</span></div>
                                <div>
                                    {localCompletedExtensions.map((item,index) => 
                                        <div key={`extension-list${option_id}${index}`}>
                                            <div className='request-properties-card-options__information__extension-list__content__centered'><span>Extension Dates:</span> {item.startDate} - {item.endDate} <span className='request-properties-card-options__information__extension-list__content__tag' style={item.interruptionReasonId === 5 ? {backgroundColor: '#05b15a'}: {backgroundColor: '#d43f3a'}}>{item.interruptionReasonId === 5 ? 'Approved' : 'Declined'}</span></div>
                                            {item.ntvRequirement && <div className='request-properties-card-options__information__extension-list__content__centered'><span>Ntv Requirement (Days):</span> {item.ntvRequirement}</div>}
                                            {item.finalRate && <div className='request-properties-card-options__information__extension-list__content__centered'><span>Final Rate:</span> {item.finalRate} {currency_code}</div>}
                                            {localCompletedExtensions?.length > (index + 1)  && <hr />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                            
                        }
                        {/* {
                            localTerminations.length > 0 &&
                            <>
                            <div className='request-properties-card-options__information__extension-list__separator'></div>
                            <div className='request-properties-card-options__information__extension-list__content'>
                                
                                <div><span>Terminations:</span></div>
                                <div>
                                    {localTerminations.map((item,index) => 
                                        <div key={`termination-list${option_id}${index}`}>
                                            <div className='request-properties-card-options__information__extension-list__content__centered'><span>Termination Date:</span> { `${new Date(item.terminationDate).getDate().toString().padStart(2,'0')}/${(new Date(item.terminationDate).getMonth() + 1).toString().padStart(2,'0')}/${new Date(item.terminationDate).getFullYear()}`}</div>
                                            {item.terminationFee && <div className='request-properties-card-options__information__extension-list__content__centered'><span>Termination Fee:</span> {item.terminationFee} {currency_code}</div>}
                                            {localCompletedExtensions.length > (index + 1)  && <hr />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                        } */}
                </div>
            </div>
        </div>
        
        
        <div className='request-properties-card-options__title'>
            <div className='request-properties-card-options__information__field'>
                <div className='request-properties-card-options__information__field__name'>Option Id:</div>
                <div className='request-properties-card-options__information__field__value'>{option_id}</div>
                  <div className='request-properties-card-options__field-status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
             </div>
            <div className='request-properties-card-options__information__field'>
                <div className='request-properties-card-options__information__field__name'>Property Name:</div>
                <div className='request-properties-card-options__information__field__value'>{property_name}</div>
            </div>
        </div>
          <div className='request-properties-card-options__status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
        <div className='request-properties-card-options__buttons'>
            <div className='request-properties-card-options__buttons__content'>
                {/* <Link target='_blank' to={`/property/dashboard/${id}`} className='request-properties-card-options__buttons__content__view'><img src={ViewIcon}/><span>View</span></Link> */}
                <Link className='request-properties-card-options__buttons__content__view' to={`/my-requests/option/${request_id}/${option_id}`} target='_blank'><img src={ViewIcon}/><span>Option Preview</span></Link>
                {/* <Link className='request-properties-card-options__buttons__content__view' to={`/property/dashboard/${property_id}`} target='_blank'><img src={ViewIcon}/><span>Property Preview</span></Link> */}
                {/* <div className='request-properties-card-options__buttons__content__delete'><img src={DeleteIcon}/><span>Delete</span></div> */}
                {/*
                      currentStatus.optionStatus === 2 && currentStatus.requestStatus === 2 ? 
                    <div className='request-properties-card-options__buttons__content__new'>
                              <ActionButton action={reserveOption}>Reserve</ActionButton>
                        <div className='request-properties-card-options__buttons__content__decline'>Decline</div>
                    </div>
                    :
                    <div className='request-properties-card-options__buttons__content__cancel'>
                        <span>X</span><span>Cancel</span>
                          </div>
  */
                    !hiddeActions && <OptionActionBtn  currentStatus={currentStatus} requestExtensionInfo={requestExtensionInfo}/>
                    // <OptionActionBtn />
                }
            </div>
        </div>
        <div className='request-properties-card-options__divisor'></div>
        <CustomPopup visible={visibleDeclinedPopup} onHiding={_ => setVisibleDeclinedPopup(false)}>
            <RequestPopupData 
                title='DECLINE THE OPTION' 
                id={option_id} 
                message='To help us provide better option for you, please choose the reason why this option does not fit for you.'
                labelAction='Decline' 
                labelCancel='Cancel' 
                action={declineOption}
                buttonPaddingLeft='145px'
                closeAction={cancelDeclineOption}
                setVisible={setVisibleDeclinedPopup}
                disabled={loading}
            >
                <div>
                    <div>
                        <CustomRadio labelSize='133px' label='Reason to decline' value={declinedReason} setValue={setDeclinedReason} requiredValue={true} items={REASONS_DECLINE} type={'vertical'}/>
                    </div>
                    <div>
                        <CustomTextarea labelSize='133px' label='Your Comment' value={commentDeclinedReason} setValue={setCommentDeclinedReason} requiredValue={false}/>
                    </div>
                </div>
            </RequestPopupData>
            
        </CustomPopup>
        <CustomPopup visible={visibleCancelReservationPopup} closeOnlyButtons={true} onHiding={_ => setVisibleCancelReservationPopup(false)}>
        <RequestPopupData 
            title='CANCEL RESERVATION FOR THE OPTION' 
            id={option_id} 
            message='After you cancel reservation you will be able to reserve another option.'
            labelAction='Cancel Reservation' 
            labelCancel='Cancel' 
            action={cancelReservation}
            setVisible={setVisibleCancelReservationPopup}
            disabled={loading}
        />
        
        </CustomPopup>
        <CustomPopup visible={visibleCancelBookingPopup} closeOnlyButtons={true} onHiding={_ => setVisibleCancelBookingPopup(false)}>
            <RequestPopupData 
                title='REQUEST BOOKING CANCELLATION' 
                // id={option_id} 
                message='Our managers are required to approve booking cancellation.'
                labelAction='Cancel Booking' 
                labelCancel='Close' 
                action={cancelBooking}
                setVisible={setVisibleCancelBookingPopup}
                disabled={loading}
            />
        </CustomPopup>
        <CustomPopup visible={visiblePendingExtensionPopup} onHiding={_ => setVisiblePendingExtensionPopup(false)}>
        <RequestPopupData 
            title='CANCEL PENDING EXTENSION FOR THE REQUEST'
            titleId = {request_id}
            id={requestExtensionInfo?.extensionId} 
            message='' 
            labelAction='Cancel Pending Extension'
            labelCancel='Close' 
            action={cancelPendingExtension}
            setVisible={setVisiblePendingExtensionPopup}
            disabled={loading}
        />
        
        </CustomPopup>
        <CustomPopup visible={acceptPendingExtensionPopup} onHiding={_ => setAcceptPendingExtensionPopup(false)}>
        <RequestPopupData 
            title='ACCEPT PENDING EXTENSION' 
            id={requestExtensionInfo?.extensionId} 
            message='Are you sure you want to accept with current requirements?' 
            isButtonGreen={true}
            labelAction='Yes' 
            labelCancel='No' 
            action={acceptPendingExtension}
            setVisible={setAcceptPendingExtensionPopup}
            disabled={loading}
        />
        
        </CustomPopup>
        <CustomPopup visible={visibleRequestExtension} onHiding={_ => setAcceptPendingExtensionPopup(false)}>
        <RequestPopupData 
            title='CREATE EXTENSION' 
            // id={requestExtensionInfo?.extensionId} 
            message='If you want to extend your request, please choose the preferred end date.' 
            isButtonGreen={true}
            buttonPaddingLeft='145px'
            labelAction='Send' 
            labelCancel='Cancel' 
            action={requestExtension}
            setVisible={cancelRequestExtension}
            disabled={loading}
            CancelButton={cancelRequestExtension}
        >
            <div>
                <CustomDate 
                    labelSize='133px'
                    label='End Date'
                    value={endDateRequestExtension}
                    setValue={setEndDateRequestExtension}
                    requiredValue={true}
                    dateFormat={RequestHelper.getLocalDateFormat() }
                    min={(new Date(new Date(startDateRequestExtension).getTime() + (24*60*60*1000))).toISOString()}
                />
            </div>
            <div>
                <CustomTextarea labelSize='133px' label='Your Comment' value={commentRequestExtensionReason} setValue={setCommentRequestExtensionReason} requiredValue={false}/>
            </div>
        </RequestPopupData>
        
        </CustomPopup>
        <CustomPopup visible={visibleRequestTermination} onHiding={_ => setVisibleRequestTermination(false)}>
            <RequestPopupData 
                title='REQUEST TERMINATION' 
                message='If you want to request a termination, please choose the preferred end date.' 
                isButtonGreen={true}
                buttonPaddingLeft='145px'
                labelAction='Request' 
                labelCancel='Cancel' 
                action={requestTermination}
                setVisible={setVisibleRequestTermination}
                disabled={loading}
                closeAction={cancelRequestTermination}
            >
                <div>
                    <CustomDate 
                        labelSize='133px'
                        label='End Date'
                        value={terminationDate}
                        setValue={setTerminationDate}
                        requiredValue={true}
                        dateFormat={RequestHelper.getLocalDateFormat() }
                        min={(new Date(new Date(checkInDate).getTime())).toISOString()}
                        max={(new Date(new Date(startDateRequestExtension).getTime())).toISOString()}
                    />
                </div>
                <div>
                    <CustomTextarea labelSize='133px' label='Your Comment' value={terminationComment} setValue={setTerminationComment} requiredValue={false}/>
                </div>
            </RequestPopupData>
        </CustomPopup>
    </div>
  )
}
function mapStateToProps({ requests }) {
    return {
        loading: requests.loading,
    };
  }

export default connect(mapStateToProps)(RequestPropertiesCard)
